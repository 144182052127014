import { useCookies } from "react-cookie";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const [cookies] = useCookies(["uut"]);

  console.log({ cookies });
  return (
    <>
      <iframe
        src="https://beta.unplex.me/login"
        frameborder="0"
        width="100%"
        style={{ height: "100vh" }}
        title="Unplex ME"
        sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
        referrerPolicy="strict-origin-when-cross-origin"
        allow="encrypted-media; fullscreen; oversized-images; picture-in-picture; sync-xhr; geolocation;"
        allowFullScreen="true"
      ></iframe>
    </>
  );
}
